import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

import Modal from "./components/modal";
import { Button } from "./components/modal/modal.styles";

var crypto = require("crypto");


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;


export const StyledButton = styled.button`
  padding: 20px;
  border-radius: 50px;
  border: none;
  background-color: var(--primary);
  padding: 20px;
  font-family: 'Gloria Hallelujah';
  font-weight: bold;
  color: var(--new-text);
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButton2 = styled.a`
  padding: 30px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 30px;
  font-family: 'Gloria Hallelujah';
  font-weight: bold;
  color: var(--primary-text);
  width: 400px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButton3 = styled.a`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-family: 'Gloria Hallelujah';
  font-weight: bold;
  color: var(--new-text);
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButton4 = styled.a`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--accent);
  padding: 10px;
  font-family: 'Gloria Hallelujah';
  color: var(--new-text);
  width: 300px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 0%;
  min-width: 100px;
  max-width: 1000px;
  min-height: 100px;
  max-height: 1000px;

  width: 100%;

  @media (min-width: 900px) {
    width: 100%;
  }
  @media (min-width: 1000px) {
    width: 100%;
  }
  transition: width 0.5s;
`;

export const StyledImg2 = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 0%;
  min-width: 100px;
  max-width: 128px;
  min-height: 100px;
  max-height: 192px;

  width: 100%;

  @media (min-width: 900px) {
    width: 100%;
  }
  @media (min-width: 1000px) {
    width: 100%;
  }
  transition: width 0.5s;
`;

export const StyledImg3 = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 0%;
  min-width: 100px;
  max-width: 256px;
  min-height: 100px;
  max-height: 384px;

  width: 100%;

  @media (min-width: 900px) {
    width: 100%;
  }
  @media (min-width: 1000px) {
    width: 100%;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  padding: 10px;
  font-family: 'Gloria Hallelujah';
  color: var(--accent);
  text-decoration: none;
`;



function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`You are claiming the above NFT using the Token ID below. Once claimed, you cannot undo or redo.`);

  const [potatoAmount, setPotatoAmount] = useState(1);
  const [costonAmount, setCostonAmount] = useState(1);

  // modal
  const [active, setActive] = useState(false);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },

    NFT_NAME_POTATO: "",
    SYMBOL_POTATO: "",
    GAS_LIMIT_POTATO: 0,

    NFT_NAME_COSTON: "",
    SYMBOL_COSTON: "",
    GAS_LIMIT_COSTON: 0,

    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });



  const potatoClaim = (artId) => {
    let gasLimit = CONFIG.GAS_LIMIT_POTATO;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Claiming...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .potatoMint(potatoAmount, artId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Transaction error. This Potato ID might have claimed already. Check your connected wallet, or try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `A ${CONFIG.NFT_NAME_POTATO} claimed an art piece!`
        );

        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const costonClaim = (artId) => {
    let gasLimit = CONFIG.GAS_LIMIT_COSTON;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Claiming...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .costonMint(costonAmount, artId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Transaction error. This Coston ID might have claimed already. Check your connected wallet, or try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `A ${CONFIG.NFT_NAME_COSTON} claimed an art piece!`
        );

        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };



  const decrementPotatoAmount10 = () => {
    let newMintAmount = potatoAmount;
    if (newMintAmount - 10 >= 1) {
      newMintAmount = potatoAmount - 10;
    }
    setPotatoAmount(newMintAmount);
  };

  const decrementPotatoAmount = () => {
    let newMintAmount = potatoAmount;
    if (newMintAmount > 1) {
      newMintAmount = potatoAmount - 1;
    }
    setPotatoAmount(newMintAmount);
  };

  const incrementPotatoAmount = () => {
    let newMintAmount = potatoAmount;
    if (newMintAmount + 1 <= 300) {
      newMintAmount = potatoAmount + 1;
    }
    setPotatoAmount(newMintAmount);
  };

  const incrementPotatoAmount10 = () => {
    let newMintAmount = potatoAmount;
    if (newMintAmount + 10 <= 300) {
      newMintAmount = potatoAmount + 10;
    }
    setPotatoAmount(newMintAmount);
  };



  const decrementCostonAmount10 = () => {
    let newMintAmount = costonAmount;
    if (newMintAmount - 10 >= 1) {
      newMintAmount = costonAmount - 10;
    }
    setCostonAmount(newMintAmount);
  };

  const decrementCostonAmount = () => {
    let newMintAmount = costonAmount;
    if (newMintAmount > 1) {
      newMintAmount = costonAmount - 1;
    }
    setCostonAmount(newMintAmount);
  };

  const incrementCostonAmount = () => {
    let newMintAmount = costonAmount;
    if (newMintAmount + 1 <= 23) {
      newMintAmount = costonAmount + 1;
    }
    setCostonAmount(newMintAmount);
  };

  const incrementCostonAmount10 = () => {
    let newMintAmount = costonAmount;
    if (newMintAmount + 10 <= 23) {
      newMintAmount = costonAmount + 10;
    }
    setCostonAmount(newMintAmount);
  };



  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);



  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "" : null}
      >


        {/*TITLE*/}
        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              CoinyLabs Potato Surprise 2
            </s.MainTitle>

            <s.ContractAddress
              style={{
                textAlign: "center",
                color: "var(--primary)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.ContractAddress>

          </s.Container>
        </ResponsiveWrapper>



        {/*FOR HOLDERS*/}
        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

          <s.Container flex={1} jc={"center"} ai={"center"}>

            <s.SpacerSmall />
            <s.TextDescription style={{ textAlign: "center", color: "var(--new-text)", }}>
              <StyledImg2 src={"/config/images/gif1.gif"} />
              <br></br>
              For holders of Potatoes
              <br></br><br></br>
              <StyledImg2 src={"/config/images/coston.png"} />
              <br></br>
              And holders of Costons 1 to 23
            </s.TextDescription>

            <s.SpacerSmall />

            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              Claim A FlareGirl Collection Piece!
            </s.MainTitle>

            <s.SpacerSmall />

            <s.TextDescription style={{ textAlign: "center", color: "var(--new-text)", }}>
              Please make sure you are connected to the right network (
              {CONFIG.NETWORK.NAME} Mainnet).
              <br></br><br></br>
              Each Token ID can only claim once, even if it changed owners.
              <br></br><br></br>
              We use {CONFIG.GAS_LIMIT_POTATO} gas limit to successfully mint.
            </s.TextDescription>

            <s.SpacerSmall />





          </s.Container>






        </ResponsiveWrapper>



        {/*ART 1*/}
        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

          <s.Container flex={1} jc={"top"} ai={"center"}
            style={{
              backgroundColor: "var(--new-text)",
              padding: 15,
              borderRadius: 24,
              border: "4px  var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
            id={"standard"}
          >

            <StyledImg3 src={"/config/images/BabyPotato.png"} />

            {/*ART 1 CONTAINER*/}
            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {/*ART 1 POTATO*/}
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>

                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--accent)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"standard"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    You have<br></br>{data.totalSupply[0]}<br></br>Potatoes
                  </s.TextTitle>

                  <span
                    style={{
                      textAlign: "center",
                    }}
                  >

                  </span>
                  <s.SpacerXSmall />
                  {Number(data.totalSupply[0]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have no claims.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledRoundButton
                              style={{ lineHeight: 0.4 }}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementPotatoAmount10();
                              }}
                            >
                              -10
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              style={{ lineHeight: 0.4 }}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementPotatoAmount();
                              }}
                            >
                              -
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {potatoAmount}
                            </s.TextDescription>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementPotatoAmount();
                              }}
                            >
                              +
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementPotatoAmount10();
                              }}
                            >
                              +10
                            </StyledRoundButton>
                          </s.Container>

                          <s.SpacerSmall />

                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Select Potato ID
                            <br></br><br></br>
                            To find your token ID, check the Explorer!
                          </s.TextDescription>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                potatoClaim(1);
                                getData();
                              }}
                            >
                              {claimingNft ? "CLAIMING..." : "CLAIM NOW"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                </s.Container>

              </s.Container>

              {/*ART 1 COSTON*/}
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>

                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--secondary)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"collector"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    You have<br></br>{data.totalSupply[1]}<br></br>Costons
                  </s.TextTitle>

                  <span
                    style={{
                      textAlign: "center",
                    }}
                  >

                  </span>
                  <s.SpacerXSmall />
                  {Number(data.totalSupply[1]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have no claims.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledRoundButton
                              style={{ lineHeight: 0.4 }}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementCostonAmount10();
                              }}
                            >
                              -10
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              style={{ lineHeight: 0.4 }}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementCostonAmount();
                              }}
                            >
                              -
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {costonAmount}
                            </s.TextDescription>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementCostonAmount();
                              }}
                            >
                              +
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementCostonAmount10();
                              }}
                            >
                              +10
                            </StyledRoundButton>
                          </s.Container>

                          <s.SpacerSmall />

                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Select Coston ID
                            <br></br><br></br>
                            To find your token ID, go to the explorer transaction where you minted/received your NFT.
                          </s.TextDescription>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                costonClaim(1);
                                getData();
                              }}
                            >
                              {claimingNft ? "CLAIMING..." : "CLAIM NOW"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                </s.Container>

              </s.Container>

            </ResponsiveWrapper>

          </s.Container>

        </ResponsiveWrapper>

        <s.SpacerLarge />
        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

        </ResponsiveWrapper>
        <s.SpacerLarge />

        {/*ART 2*/}
        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

          <s.Container flex={1} jc={"top"} ai={"center"}
            style={{
              backgroundColor: "var(--new-text)",
              padding: 15,
              borderRadius: 24,
              border: "4px  var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
            id={"standard"}
          >

            <StyledImg3 src={"/config/images/ChibiPotato.png"} />

            {/*ART 2 CONTAINER*/}
            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {/*ART 2 POTATO*/}
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>

                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--accent)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"standard"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    You have<br></br>{data.totalSupply[0]}<br></br>Potatoes
                  </s.TextTitle>

                  <span
                    style={{
                      textAlign: "center",
                    }}
                  >

                  </span>
                  <s.SpacerXSmall />
                  {Number(data.totalSupply[0]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have no claims.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledRoundButton
                              style={{ lineHeight: 0.4 }}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementPotatoAmount10();
                              }}
                            >
                              -10
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              style={{ lineHeight: 0.4 }}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementPotatoAmount();
                              }}
                            >
                              -
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {potatoAmount}
                            </s.TextDescription>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementPotatoAmount();
                              }}
                            >
                              +
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementPotatoAmount10();
                              }}
                            >
                              +10
                            </StyledRoundButton>
                          </s.Container>

                          <s.SpacerSmall />

                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Select Potato ID
                            <br></br><br></br>
                            To find your token ID, check the Explorer!
                          </s.TextDescription>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                potatoClaim(2);
                                getData();
                              }}
                            >
                              {claimingNft ? "CLAIMING..." : "CLAIM NOW"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                </s.Container>

              </s.Container>

              {/*ART 2 COSTON*/}
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>

                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--secondary)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"collector"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    You have<br></br>{data.totalSupply[1]}<br></br>Costons
                  </s.TextTitle>

                  <span
                    style={{
                      textAlign: "center",
                    }}
                  >

                  </span>
                  <s.SpacerXSmall />
                  {Number(data.totalSupply[1]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have no claims.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledRoundButton
                              style={{ lineHeight: 0.4 }}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementCostonAmount10();
                              }}
                            >
                              -10
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              style={{ lineHeight: 0.4 }}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementCostonAmount();
                              }}
                            >
                              -
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {costonAmount}
                            </s.TextDescription>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementCostonAmount();
                              }}
                            >
                              +
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementCostonAmount10();
                              }}
                            >
                              +10
                            </StyledRoundButton>
                          </s.Container>

                          <s.SpacerSmall />

                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Select Coston ID
                            <br></br><br></br>
                            To find your token ID, go to the explorer transaction where you minted/received your NFT.
                          </s.TextDescription>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                costonClaim(2);
                                getData();
                              }}
                            >
                              {claimingNft ? "CLAIMING..." : "CLAIM NOW"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                </s.Container>

              </s.Container>

            </ResponsiveWrapper>

          </s.Container>

        </ResponsiveWrapper>

        <s.SpacerLarge />
        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

        </ResponsiveWrapper>
        <s.SpacerLarge />

        {/*ART 3*/}
        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

          <s.Container flex={1} jc={"top"} ai={"center"}
            style={{
              backgroundColor: "var(--new-text)",
              padding: 15,
              borderRadius: 24,
              border: "4px  var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
            id={"standard"}
          >

            <StyledImg3 src={"/config/images/PotatoePaintsPotatoe.png"} />

            {/*ART 3 CONTAINER*/}
            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {/*ART 3 POTATO*/}
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>

                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--accent)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"standard"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    You have<br></br>{data.totalSupply[0]}<br></br>Potatoes
                  </s.TextTitle>

                  <span
                    style={{
                      textAlign: "center",
                    }}
                  >

                  </span>
                  <s.SpacerXSmall />
                  {Number(data.totalSupply[0]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have no claims.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledRoundButton
                              style={{ lineHeight: 0.4 }}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementPotatoAmount10();
                              }}
                            >
                              -10
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              style={{ lineHeight: 0.4 }}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementPotatoAmount();
                              }}
                            >
                              -
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {potatoAmount}
                            </s.TextDescription>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementPotatoAmount();
                              }}
                            >
                              +
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementPotatoAmount10();
                              }}
                            >
                              +10
                            </StyledRoundButton>
                          </s.Container>

                          <s.SpacerSmall />

                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Select Potato ID
                            <br></br><br></br>
                            To find your token ID, check the Explorer!
                          </s.TextDescription>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                potatoClaim(3);
                                getData();
                              }}
                            >
                              {claimingNft ? "CLAIMING..." : "CLAIM NOW"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                </s.Container>

              </s.Container>

              {/*ART 3 COSTON*/}
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>

                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--secondary)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"collector"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    You have<br></br>{data.totalSupply[1]}<br></br>Costons
                  </s.TextTitle>

                  <span
                    style={{
                      textAlign: "center",
                    }}
                  >

                  </span>
                  <s.SpacerXSmall />
                  {Number(data.totalSupply[1]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have no claims.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledRoundButton
                              style={{ lineHeight: 0.4 }}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementCostonAmount10();
                              }}
                            >
                              -10
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              style={{ lineHeight: 0.4 }}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementCostonAmount();
                              }}
                            >
                              -
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {costonAmount}
                            </s.TextDescription>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementCostonAmount();
                              }}
                            >
                              +
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementCostonAmount10();
                              }}
                            >
                              +10
                            </StyledRoundButton>
                          </s.Container>

                          <s.SpacerSmall />

                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Select Coston ID
                            <br></br><br></br>
                            To find your token ID, go to the explorer transaction where you minted/received your NFT.
                          </s.TextDescription>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                costonClaim(3);
                                getData();
                              }}
                            >
                              {claimingNft ? "CLAIMING..." : "CLAIM NOW"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                </s.Container>

              </s.Container>

            </ResponsiveWrapper>

          </s.Container>

        </ResponsiveWrapper>


        <s.SpacerLarge />
        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

        </ResponsiveWrapper>
        <s.SpacerLarge />


        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>

          <s.SpacerSmall />

        </s.Container>

      </s.Container>
    </s.Screen>
  );
}

export default App;